import { useEffect, useState } from "preact/hooks";
import { showFile } from "../utils/file.ts";
import {
  CartIcon,
  InfoIconRounded,
  OutOfStockIcon,
} from "../components/SVGs.tsx";
import { AlertToast } from "../utils/helper.ts";

function ProductTile(
  {
    products,
    setShowNotification,
    setNotificationType,
    setMessage,
    handleShow,
    isSearch,
    search,
  }: any,
) {
  useEffect(() => {
    if (isSearch) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-y-hidden");
    };
  }, [isSearch]);

  const addToCart = (product: any, quantity: number) => {
    const totalPrice = product.variants[0].price * quantity;
    const cartItem = {
      id: product._id,
      name: product.name,
      image: product.image,
      quantity: quantity,
      price: Number(totalPrice),
      category: product.category,
      variants: {
        id: product.variants[0].id,
        unit: product.variants[0].unit,
        quantity: product.variants[0].quantity,
        price: product.variants[0].price,
      },
    };
    const cart = JSON.parse(localStorage.getItem("cart-1") || "[]");
    const existingCartItem = cart.find((item: any) => item.id === cartItem.id);
    if (existingCartItem) {
      AlertToast("Produkten finns redan i din korg!");
    } else {
      cart.push(cartItem);
      localStorage.setItem("cart-1", JSON.stringify(cart));
      AlertToast("Produkten har lagts till i korgen!");
    }
  };

  const isWidth = globalThis.innerWidth >= 768 && globalThis.innerWidth <=1023;

  return (
    <div
      className={`row ${
        isSearch && products?.length > 0
          ? " overflow-scroll max-h-[80vh] !overflow-x-hidden mt-2"
          : ""
      }`}
    >
      {products &&
          products.length > 0
        ? products.map((product: any, i: number) => {
          const p = product.variants[0];
          const stock = product?.countInStock;
          const [quantity, setQuantity] = useState(1);

          const incrementQuantity = () => {
            setQuantity((prevQuantity) => prevQuantity + 1);
          };

          const decrementQuantity = () => {
            if (quantity > 1) {
              setQuantity((prevQuantity) => prevQuantity - 1);
            }
          };

          return (
            <div className="col-lg-12 p15" key={product._id}>
              <div
                className={`product-tile row ${isSearch ? "rounded-lg" : ""}`}
              >
                {/* Mobile View */}
                <div class="mob-view">
                  <div class="w-full">
                    <img
                      className={isSearch
                        ? "min-h-16 min-w-16 img-size"
                        : `ptile-img`}
                      src={showFile(product.image)}
                      alt={`Image-${i}`}
                    />
                  </div>
                  <div class="mob-design">
                    <div>{product.name}</div>
                    <div class="ptile-price">{p.price}kr</div>
                  </div>
                  <div class="mob-design-2 flex">
                    <div
                      className={`ptile-cart self-end !flex flex-col items-center gap-3 mob-design-3 ${
                        stock > 0 ? "mr-5" : "mr-1"
                      }`}
                    >
                      {!isSearch && (
                        <button
                          className="base-btn bg-[#3f51b5] text-white inline-flex items-center gap-0"
                          onClick={() => handleShow(product?.description)}
                        >
                          <i className="fas fa-cart-plus">
                            <InfoIconRounded />
                          </i>
                          <span class="mob-hidden">&nbsp;Info</span>
                        </button>
                      )}
                      {stock > 0
                        ? (
                          <button
                            onClick={() => addToCart(product, quantity)}
                            className={`${
                              isSearch ? "!text-sm" : ""
                            } base-btn bg-[#3f51b5] text-white inline-flex items-center gap-0`}
                          >
                            <i className="fas fa-cart-plus">
                              <CartIcon />
                            </i>
                            <span class="mob-hidden">&nbsp;Köp</span>
                          </button>
                        )
                        : (
                          <button
                            className={`${
                              isSearch ? "!text-sm" : ""
                            } base-btn bg-[#596acb] text-gray-100 !cursor-not-allowed inline-flex items-center gap-0`}
                          >
                            <i className="fas fa-cart-plus">
                              <OutOfStockIcon />
                            </i>
                            <span class="mob-hidden">
                              &nbsp;Slut i lager
                            </span>
                          </button>
                        )}
                    </div>
                  </div>
                </div>

                {/* Mobile View-End */}

                <div className="col-lg-3 p0 mob-hidden new-1">
                  <img
                    className={`${isWidth ? "!h-60 !max-h-60 !rounded-none":""} ${isSearch ? "min-h-16 min-w-16 img-size": `ptile-img`}`}
                    src={showFile(product.image)}
                    alt={`Image-${i}`}
                  />
                </div>
                <div className="col-lg-9 p0 new-2">
                  <div className="ptile-content mob-hidden">
                    <div className="row relative">
                      <div
                        className={`${
                          isSearch ? "!text-lg" : ""
                        } col-lg-8 ptile-title`}
                      >
                        {product.name}
                        {/* {product.name}&nbsp;{p.quantity}&nbsp;{p.unit} */}
                      </div>
                      <div
                        class={`tab-view flex justify-end flex-col gap-2 items-center pb-2 absolute ${
                          isSearch ? "top-3" : "top-6"
                        }`}
                      >
                        <div
                          className={`${
                            isSearch ? "!text-lg" : ""
                          } text-end ptile-price self-end pt-0 mr-8`}
                        >
                          {p.price}kr
                        </div>
                        {!isSearch && (
                          <div className="ptile-cart self-end mr-5">
                            <button
                              className="dec-qty"
                              onClick={decrementQuantity}
                            >
                              -
                            </button>
                            <div className="product-qty">{quantity}</div>
                            <button
                              className="inc-qty"
                              onClick={incrementQuantity}
                            >
                              +
                            </button>
                          </div>
                        )}
                        <div
                          className={`ptile-cart self-end !flex flex-col items-center gap-3 ${
                            stock > 0 ? "mr-5" : "mr-1"
                          }`}
                        >
                          {!isSearch && (
                            <button
                              className="base-btn bg-[#3f51b5] text-white inline-flex items-center gap-0"
                              onClick={() => handleShow(product?.description)}
                            >
                              <i className="fas fa-cart-plus">
                                <InfoIconRounded />
                              </i>
                              <span class="mob-hidden">&nbsp;Info</span>
                            </button>
                          )}
                          {stock > 0
                            ? (
                              <button
                                onClick={() => addToCart(product, quantity)}
                                className={`${
                                  isSearch ? "!text-sm" : ""
                                } base-btn bg-[#3f51b5] text-white inline-flex items-center gap-0`}
                              >
                                <i className="fas fa-cart-plus">
                                  <CartIcon />
                                </i>
                                <span class="mob-hidden">&nbsp;Köp</span>
                              </button>
                            )
                            : (
                              <button
                                className={`${
                                  isSearch ? "!text-sm" : ""
                                } base-btn bg-[#596acb] text-gray-100 !cursor-not-allowed inline-flex items-center gap-0`}
                              >
                                <i className="fas fa-cart-plus">
                                  <OutOfStockIcon />
                                </i>
                                <span class="mob-hidden">
                                  &nbsp;Slut i lager
                                </span>
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
        : (
          <>
            {search?.trim() === ""
              ? (
                <div class="flex justify-center items-center pt-3 text-sm font-light">
                  Sök efter en produkt
                </div>
              )
              : (
                <div class="flex justify-center items-center pt-3 text-sm font-light">
                  Inga resultat (No results)
                </div>
              )}
          </>
        )}
    </div>
  );
}

export default ProductTile;

